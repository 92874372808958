export default {
  auth: {
    token: null,
    profile: null,
  },
  category: [
    {
      code: "...",
      created_at: "2020-10-20T18:40:15.000000Z",
      id: 0,
      layout: null,
      name: "...",
      order: 0,
      programs: [],
      show_title: true,
      translations: [],
      updated_at: "2020-10-20T18:40:15.000000Z",
    },
  ],
  categories: [],
  presenters: [],
  promocode: {},
  programs: {},

  currentProgram: null,

  sensei: [],

  my_videos: [],
  subscriptionPlans: [],
  // This array will be filled with the partner's subscription plans
  partnerSubscriptionPlans: [],

  language: {
    call_to_action: "Join the seminar",
    my_videos_title: "My Videos",
    seminars_title: "Find a Virtual Seminar",
    trailer: "Trailer",
  },

  appTextItems: {},
};
