import moment from "moment";
import findLastIndex from "lodash/findLastIndex";
export default {
  isAuthenticated: state => state.auth.token != null,

  user: state => state.auth.profile,

  getAppText: state => key => {
    if (state.appTextItems[key]) return state.appTextItems[key];

    return "";
  },

  getCategory: (state, id) => {
    let categoryIndex = findLastIndex(state.category, item => item.id == id);
    return categoryIndex > 0 ? state.category[categoryIndex] : null;
  },

  paidForSubscription: (state, getters) => {
    let user = getters.user;
    if (user && user.subscription) {
      let trialfinish = moment().diff(user.subscription.trial_ends_at, "days");
      let subsfinish = moment().diff(user.subscription.ends_at, "days");
      if (
        (user.subscription.status === "canceled" && trialfinish <= 0) ||
        (user.subscription.status === "canceled" && subsfinish <= 0)
      ) {
        return true;
      }
    }
    return (
      user &&
      user.subscription &&
      ["active", "trialing"].includes(user.subscription.status)
    );
  },
};
