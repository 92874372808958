<template>
  <ul class="list">
    <li v-for="(item, index) in items" class="pl-5 mb-3" :key="index">
      {{ item }}
    </li>
  </ul>
</template>
<script>
export default {
  name: "styled-list",

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
ul.list > li {
  list-style-type: none;
  background-image: url("~@/assets/img/logo_black.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 40px;
}
</style>
