import axios from "axios";
import Category from "@/api/Category";
import Presenter from "@/api/Presenter";
import Program from "@/api/Program";
import SubscriptionPlan from "@/api/SubscriptionPlan";

export default {
  login({ commit, dispatch }, credentials) {
    return axios.post("/auth/login", credentials).then((response) => {
      let token = response.data.token;

      commit("setAuthToken", token);

      return dispatch("fetchProfile");
    });
  },
  register({ commit, dispatch }, newUserData) {
    var url = "";
    if (newUserData.partnerId === undefined || newUserData.partnerId === "") {
      url = "/auth/register";
    } else {
      url = "/auth/register/" + newUserData.partnerId;
    }

    return axios.post(url, newUserData).then((response) => {
      let token = response.data.token;

      commit("setAuthToken", token);

      return dispatch("fetchProfile");
    });
  },

  registerWitouthPassword({ commit, dispatch }, newUserData) {
    return axios
      .post("auth/register", newUserData, {
        params: { withRandomPassword: "" },
      })
      .then((response) => {
        let token = response.data.token;

        commit("setAuthToken", token);

        return dispatch("fetchProfile");
      });
  },

  fetchProfile(context) {
    if (!context.getters.isAuthenticated) {
      return Promise.resolve();
    }

    return axios("/me/profile")
      .then((response) => response.data.data)
      .then((profileData) => {
        context.commit("setAuthProfile", profileData);
      });
  },

  logout({ commit }) {
    commit("setAuthToken", null);
    commit("setAuthProfile", null);
  },

  async fetchAppTextItems({ commit }) {
    let { data: items } = await axios("app-text-items").then((r) => r.data);

    let formattedItems = {};

    items.forEach((item) => (formattedItems[item.key] = item.value));

    commit("setAppTextItems", formattedItems);
  },
  async fetchPresenters({ commit }) {
    Presenter.$get().then((presenters) => {
      commit("setPresenters", presenters);
    });
  },
  async fetchCategories({ commit }) {
    Category.orderBy("order")
      .include("featuredPrograms")
      .$get()
      .then((categories) => {
        commit("setCategories", categories);
      });
  },

  // eslint-disable-next-line no-unused-vars
  async fetchCategory({ commit }, id) {
    Category.$find(id).then((category) => {
      commit("setCategory", category);
    });
  },

  async fetchProgramBySlug({ commit }, slug) {
    let program = await Program.$find("slug/" + slug);
    commit("setPrograms", [program]);
    commit("setCurrentProgram", program);
  },

  async fetchProgram({ state, commit }, programId) {
    commit("setCurrentProgram", state.programs[programId]);

    let program = await Program.$find(programId);

    commit("setPrograms", [program]);
    commit("setCurrentProgram", program);
  },

  async fetchPartnerSubscriptionPlansById({ commit }, id) {
    console.log("3px partner id:", id);
    let subscriptionPlans = await SubscriptionPlan.$find(id.id);
    let subsArray = Object.keys(subscriptionPlans).map(function (key) {
      return subscriptionPlans[key];
    });
    commit("setPartnerSubscriptionPlans", subsArray);
  },

  async fetchSubscriptionPlans({ commit }) {
    let subscriptionPlans = await SubscriptionPlan.$get();

    commit("setSubscriptionPlans", subscriptionPlans);
  },
};
