<template>
  <footer class="footer">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 text-center mb-1">
          <a
            href="https://www.instagram.com/bujintv"
            rel="noreferrer"
            class="mr-1"
            target="_blank"
          >
            <img
              width="40"
              height="40"
              src="~@/assets/img/instagram_icon_white.svg"
              alt="Bujin.tv Instagram"
              :style="{ width: '40px' }"
            />
          </a>
          <a
            href="https://www.facebook.com/bujintv"
            rel="noreferrer"
            target="_blank"
          >
            <img
              width="40"
              height="40"
              src="~@/assets/img/facebook_icon_white.svg"
              alt="Bujin.tv Facebook"
              :style="{ width: '40px' }"
            />
          </a>
        </div>

        <div class="col-12 text-center text-white mb-1">
          <router-link :to="{ name: 'terms' }" class="text-default text-white"
            >Terms of Use</router-link
          >

          <span class="mx-2">|</span>

          <router-link
            :to="{ name: 'privacy' }"
            class="text-default text-white"
          >
            Privacy Policy
          </router-link>
          <span class="mx-2">|</span>

          <router-link
            :to="{ name: 'contact-us' }"
            class="text-default text-white"
          >
            Contact Us
          </router-link>
        </div>
        <div class="col-12">
          <div></div>
          <div class="text-sm text-center text-white">
            Copyright &copy; {{ year }} Bujin Media, LLP - Built with 💪 for all
            the 🥋 around the globe
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
