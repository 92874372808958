import Model from "./Model";

const FEATURED = "featured";

export default class Category extends Model {
  resource() {
    return "category";
  }

  static get featured() {
    return FEATURED;
  }
}
