<template>
  <div>
    <section
      class="bujin-banner d-flex flex-column my-0 pt-lg pb-3 pb-sm-md px-sm min-h-66"
    >
      <!-- :style="{ minHeight: '750px' }" -->
      <div
        class="flex-grow-1 d-flex flex-column align-items-center justify-content-between justify-content-sm-end"
      >
        <div class="d-flex flex-column align-items-center pb-sm-2">
          <logo width="500" height="157" shadow class="mw-100" />
          <h1
            class="text-lg text-sm-3xl text-center text-white font-weight-900 px-3 mb-3 mb-sm-sm"
          >
            The first video streaming platform for martial arts
          </h1>

          <landing-plan-buttons
            trial-text-class="text-white"
          ></landing-plan-buttons>

          <span class="text-sm text-light text-center"
            >** all transactions will be handled in USD. Currency rates shown
            are for reference only.</span
          >
        </div>
      </div>
    </section>
    <section class="d-flex flex-column">
      <router-link :to="{ name: 'shop' }">
        <img
          width="1920"
          height="300"
          v-lazy="require('@/assets/img/store-banner.jpg')"
          class="img-fluid d-md-none"
          id="gift-banner"
          alt="Bujin.tv Annual Subscription Gift Card"
        />
        <img
          width="1920"
          height="124"
          v-lazy="require('@/assets/img/store-banner.jpg')"
          class="img-fluid d-none d-md-block"
          id="gift-banner-2"
          alt="Bujin.tv Annual Subscription Gift Card"
        />
      </router-link>
    </section>
    <section
      class="section pt-md pb-md pb-sm-lg px-sm d-flex flex-column align-items-center"
    >
      <router-link :to="{ name: 'subscribe' }">
        <h2 class="text-center text-2xl font-weight-900 mb-md">
          Sign up and enjoy carefully selected martial arts content <br />
          from internationally renowned instructors
        </h2>
      </router-link>
      <div class="container mb-sm">
        <div class="row justify-content-around">
          <div
            v-for="(feature, index) in features"
            class="col-12 col-sm-6 col-md-4 mb-sm ms-sm-"
            :class="{ 'mb-sm-lg': index < 2 }"
            :key="index"
          >
            <div class="d-flex flex-column align-items-center w-80 mx-auto">
              <img
                v-lazy="feature.iconUrl"
                :style="feature.iconSize"
                :alt="feature.title"
                class="img-fluid mb-1 mb-sm-sm"
              />

              <span class="text-xl text-center text-black font-weight-600">
                {{ feature.title }}
              </span>

              <p class="text-base text-center lh-150 font-weight-500">
                {{ feature.description }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container mb-sm">
        <h2
          class="text-lg text-sm-3xl text-center text-black font-weight-900 px-3 mb-3 mb-sm-sm"
        >
          The first video streaming platform for martial arts
        </h2>

        <div class="row justify-content-center">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
            <img
              width="255"
              height="150"
              v-lazy="require('@/assets/img/landing_content_example_1.png')"
              class="img-fluid"
              alt="Content Example"
            />
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
            <img
              width="255"
              height="150"
              v-lazy="require('@/assets/img/landing_content_example_2.jpg')"
              class="img-fluid"
              alt="Content Example"
            />
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
            <img
              width="255"
              height="150"
              v-lazy="require('@/assets/img/landing_content_example_3.jpg')"
              class="img-fluid"
              alt="Content Example"
            />
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 text-center">
            <img
              width="255"
              height="150"
              v-lazy="require('@/assets/img/landing_content_example_4.png')"
              class="img-fluid"
              alt="Content Example"
            />
          </div>
        </div>
      </div>

      <div>
        <h1
          class="text-lg text-sm-3xl text-center text-black font-weight-900 px-3 mb-3 mb-sm-sm"
        >
          Choose your plan
        </h1>

        <landing-plan-buttons
          class="mb-sm"
          trial-text-class="text-black"
        ></landing-plan-buttons>

        <div class="d-flex justify-content-center">
          <styled-list
            :items="[
              'Videos on modern and traditional martial arts',
              'Full workout routines, drills and techniques',
              'Supplementary training tips',
              'Motivation and advice from experts',
            ]"
          ></styled-list>
        </div>
      </div>
    </section>

    <section class="content-providers-section section bg-primary pt-md pb-lg">
      <div class="container-fluid container-lg">
        <div class="row">
          <div class="col-12 col-md-6 text-center mb-sm mb-md-0">
            <img
              width="350"
              height="505"
              v-lazy="require('@/assets/img/landing_karateka.png')"
              alt="Karateka image"
              class="karateka-image mw-100 m-h-auto"
            />
          </div>

          <div class="col-12 col-md-6">
            <div class="text-center text-md-left mb-md">
              <span class="text-3xl font-weight-800 text-white mb-sm">
                Content Creators
              </span>

              <p class="text-lg lh-150 font-weight-400 text-white">
                Bujin.tv is building partnerships with martial arts content
                creators around the world. We want to hear from you too.
              </p>

              <p class="text-lg lh-150 font-weight-400 text-white">
                Our mission is to build your audience and reward you so you can
                keep making great content. Contact us today and let's start
                working together.
              </p>
            </div>

            <div class="text-center">
              <base-button
                tag="router-link"
                :to="{ name: 'become-content-provider' }"
                class="text-md font-weight-800 px-sm-5"
                type="danger"
              >
                Become a Content Provider
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section pt-sm pb-lg">
      <span
        class="text-3xl text-black font-weight-900 text-center mb-md w-100 d-block"
      >
        Frequently Asked Questions
      </span>
      <base-collapsable :faq-items="this.faqItems"></base-collapsable>
    </section>

    <section class="section section-sm bg-red">
      <div class="container-fluid container-lg">
        <div class="row mb-sm">
          <div class="col-12 col-md-5">
            <span class="text-white font-weight-900 text-md-left mb-sm text-xl">
              Enjoy on <br />
              your favorite device
            </span>
          </div>
          <div class="col-12 col-md-7">
            <img
              src="@/assets/img/devices.svg"
              width="635"
              height="348"
              class="img-fluid mt-md--7"
              alt="devices"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-5">
            <span
              class="text-white font-weight-900 text-center text-md-left mb-sm text-xl"
            >
              Soon Available on
            </span>
          </div>
          <div class="col-12 col-md-7">
            <div class="container">
              <div class="row justify-content-center">
                <!--                <a href="" class="d-block col-7 col-sm-4 mb-3 mb-sm-0">-->
                <img
                  width="182"
                  height="54"
                  alt="Apple App Store"
                  src="@/assets/img/apple_app_store.svg"
                  class="img-fluid d-block col-7 col-sm-4 mb-3 mb-sm-0"
                />
                <!--                </a>-->

                <!--                <a href="" class="d-block col-7 col-sm-4 mb-3 mb-sm-0">-->
                <img
                  width="182"
                  height="54"
                  alt="roku"
                  src="@/assets/img/roku_app_store.svg"
                  class="img-fluid d-block col-7 col-sm-4 mb-3 mb-sm-0"
                />
                <!--                </a>-->

                <!--                <a href="" class="d-block col-7 col-sm-4">-->
                <img
                  width="182"
                  height="54"
                  alt="google play store"
                  src="@/assets/img/google_play_app_store.svg"
                  class="img-fluid d-block col-7 col-sm-4"
                />
                <!--                </a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import LandingPlanButtons from "@/components/Landing/PlanButtons";
import StyledList from "@/components/StyledList";
import BaseCollapsable from "@/components/BaseCollapsable";

export default {
  name: "landing",
  metaInfo() {
    const backgroundImageUrl = "@/assets/img/bujin_tiles.jpg";
    const structuredData = {
      "@context": "http://schema.org",
      "@type": "FAQPage",
      mainEntity: this.faqItems.map((faqItem) => ({
        "@type": "Question",
        name: faqItem.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: faqItem.answer,
        },
      })),
    };
    return {
      link: [
        {
          rel: "canonical",
          href: "https://bujin.tv",
        },
        {
          rel: "preload",
          as: "image",
          href: backgroundImageUrl,
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: structuredData,
        },
      ],
    };
  },
  components: {
    BaseCollapsable,
    Logo,
    LandingPlanButtons,
    StyledList,
  },
  data() {
    return {
      images: {
        karatekas: require("@/assets/img/landing_main.png"),
        tiles: require("@/assets/img/bujin_tiles.jpg"),
      },
      features: [
        {
          title: "Seminars",
          description:
            "international seminars from world renowned teachers in Okinawa and Japan",
          iconUrl: require("@/assets/img/landing_icon_1.svg"),
          iconSize: { width: "76px", height: "70px" },
        },
        {
          title: "Interviews",
          description:
            "discover the history and secrets of martial arts masters as they tell their stories",
          iconUrl: require("@/assets/img/landing_icon_2.svg"),
          iconSize: { width: "73px", height: "65px" },
        },
        {
          title: "Documentaries",
          description:
            "Documentaries from Okinawa, Japan and China, uncovering the truth of the martial arts",
          iconUrl: require("@/assets/img/landing_icon_3.svg"),
          iconSize: { width: "80px", height: "69px" },
        },
        {
          title: "Instructional Videos",
          description:
            "our personal selection of the best instructional videos in karate, kung fu and other Asian martial arts, from leading teachers",
          iconUrl: require("@/assets/img/landing_icon_4.svg"),
          iconSize: { width: "118px", height: "70px" },
        },
        {
          title: "Podcasts",
          description:
            "insightful, entertaining, hard hitting and fun podcasts from our favourite podcasters around the world of MA",
          iconUrl: require("@/assets/img/landing_icon_5.svg"),
          iconSize: { width: "72px", height: "80px" },
        },
      ],

      // In order to handle multi-lang, this should later be fetched from the API
      faqItems: [
        {
          question: "What is Bujin.tv?",
          image: "https://via.placeholder.com/1280x720.png",
          answer:
            "Bujin.tv is a streaming service for martial arts fans everywhere. Featuring instructional videos, seminars, documentaries, interviews, podcasts and movies. From top notch instructors and video producers around the world.You can watch what you want, when you want, with no annoying ads or commercials – all for one low monthly fee. We will keep adding new videos every week so there will always be more to discover and enjoy.",
        },
        {
          question: "How much does Bujin.tv cost?",
          image: "",
          answer:
            "Bujin.tv is accessible via your PC, smartphone and other devices all for one fixed monthly fee. Just $12.99 a month or $129.00 for one year (pre-tax). No extra costs, no contracts. Your first 7 days are a free trial.",
        },
        {
          question: "What can I watch on Bujin.tv? ",
          image: "",
          answer:
            "Bujin.tv is building a carefully selected catalog of top quality martial arts related videos. We bring together content from leading instructors and producers from around the world. We work with our content partners to bring their best content onto Bujin.tv and we invest in producing new original and exclusive content for our service. You will discover videos from some of the top names in online martial arts on Bujin.tv.",
        },
        {
          question: "How do I cancel?",
          image: "",
          answer:
            "If you change your mind it's easy to cancel. There are no contracts and no commitments. You can easily cancel your account via links on your Account page. There are no cancellation fees – start or stop your account anytime.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.bujin-banner {
  background: #333
    linear-gradient(0deg, rgba(35, 33, 34, 0) 0%, rgba(7, 7, 7, 1) 100%);
  background-image: url("~@/assets/img/bujin_tiles.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.content-providers-section {
  background-image: url("~@/assets/img/logo_white_transparent_10.svg");
  background-size: 100%;
  background-position: 0 40px;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    background-size: 60%;
  }

  .karateka-image {
    height: 400px;
    margin-top: -120px;
    @media (min-width: 768px) {
      position: absolute;
      left: -20px;
      bottom: -4rem;
      height: 160%;
      width: auto;
      margin-top: 0;
    }
    @media (min-width: 992px) {
      left: 0;
      height: 165%;
    }
  }
}
</style>
