import Vue from "vue";
import VueMeta from "vue-meta";
import VueGtm from "vue-gtm";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import App from "./App.vue";
import store from "@/store/store";
import router from "@/router/router";
import Argon from "./plugins/dashboard-plugin";
import VueLazyload from "vue-lazyload";
import axios from "axios";
import VueAxios from "vue-axios";
import { Model } from "vue-api-query";
import Notifications from "vue-notification";
import VueSocialSharing from "vue-social-sharing";
import "bootstrap-icons/font/bootstrap-icons.scss";
import "./util/klaviyo"; // Klaviyo
import { updateMeta } from "./functions/func.js";

Bugsnag.start({
  apiKey: "e8ff2d6d2d6f994c960906e3b8309194",
  plugins: [new BugsnagPluginVue()],
});

Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);

axios.defaults.headers.common["Accept-Language"] = "en";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Model.$http = axios;

Vue.config.productionTip = false;

Vue.use(Argon);
Vue.use(VueLazyload, {
  filter: {
    webp(listener, options) {
      if (!options.supportWebp) return;
      const folder = "/img/";
      if (folder === listener.src.substring(0, 5)) {
        listener.src = listener.src.replace(".png", ".webp");
        listener.src = listener.src.replace(".jpg", ".webp");
      }
    },
  },
});
Vue.use(VueAxios, axios);

Vue.use(Notifications);
//Vue Social Sharing
Vue.use(VueSocialSharing);
Vue.use(VueMeta);
Vue.prototype.$appText = store.getters.getAppText;
Vue.prototype.$updateMeta = updateMeta;
store.commit("initStore");

Vue.use(VueGtm, {
  id: "GTM-MKNQ9DJ", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  store,
  router,
  beforeCreate() {
    this.$store.dispatch("fetchProfile");
    this.$store.dispatch("fetchAppTextItems");
  },

  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");
