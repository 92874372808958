import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store";

import publicRoutes from "./publicRoutes";
// import adminRoutes from "./adminRoutes";

const NotFound = () =>
  import(/* webpackChunkName: "not-found" */ "@/views/NotFound.vue");

Vue.use(Router);

let router = new Router({
  mode: "history",

  linkExactActiveClass: "active",

  routes: [
    ...publicRoutes,
    // ...adminRoutes,

    {
      path: "/404",
      component: NotFound,
    },

    {
      path: "*",
      redirect: "/404",
    },
  ],

  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const isSubscribed = store.getters.paidForSubscription;

  if (to.matched.some(route => route.meta.requiresAuth) && !isAuthenticated) {
    next({
      name: "login",
      params: { nextUrl: to.fullPath },
    });

    return;
  }

  if (to.matched.some(route => route.meta.guestsOnly) && isAuthenticated) {
    next({
      name: "main",
    });

    return;
  }

  if (to.matched.some(route => route.meta.nonSubscribersOnly) && isSubscribed) {
    next({
      name: "main",
    });

    return;
  }

  if (
    to.matched.some(route => route.meta.requiresAuth) &&
    isAuthenticated &&
    !isSubscribed
  ) {
    next({
      name: "subscribe",
      params: { nextUrl: to.fullPath },
    });

    return;
  }

  next();
});

export default router;
