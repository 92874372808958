<template>
  <div id="app" class="d-flex flex-column" style="min-height: 100vh">
    <router-view name="header"></router-view>

    <main class="position-relative flex-grow-1 d-flex">
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view class="w-100" />
      </fade-transition>
    </main>

    <router-view name="footer"></router-view>

    <notifications />
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  name: "Bujin.tv",
  components: {
    FadeTransition,
  },
  metaInfo: {
    meta: [
      {
        charset: "utf-8",
      },
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0, maximum-scale=5, user-scalable=1, shrink-to-fit=no",
      },
      {
        name: "description",
        content:
          "Bujin.tv - The first video streaming platform for martial arts",
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        name: "msapplication-TileColor",
        content: "#da532c",
      },
      {
        name: "theme-color",
        content: "#ffffff",
      },
    ],
  },
};
</script>
