import AppHeader from "@/layout/AppHeader";
import AppFooter from "@/layout/AppFooter";
import Landing from "@/views/Landing.vue";

const Gift = () => import(/* webpackChunkName: "gift"*/ "@/views/Gift.vue");

const Download = () =>
  import(/* webpackChunkName: "download"*/ "@/views/Download.vue");

const Login = () => import(/* webpackChunkName: "login" */ "@/views/Login.vue");

const ForgotPassword = () =>
  import(
    /* webpackChunkName: "forgot-password" */ "@/views/ForgotPassword.vue"
  );
const Subscribe = () =>
  import(/* webpackChunkName: "subscribe" */ "@/views/Subscribe.vue");

const SubscribePartner = () =>
  import(/* webpackChunkName: "subscribe" */ "@/views/SubscribePartner.vue");

const Account = () =>
  import(/* webpackChunkName: "account" */ "@/views/Account.vue");

const Main = () => import(/* webpackChunkName: "main" */ "@/views/Main.vue");

const Programs = () =>
  import(/* webpackChunkName: "programs" */ "@/views/Programs.vue");

const Search = () =>
  import(/* webpackChunkName: "search" */ "@/views/Search.vue");

const ProgramDetail = () =>
  import(/* webpackChunkName: "program-detail" */ "@/views/ProgramDetail.vue");

const CategoryDetail = () =>
  import(
    /* webpackChunkName: "category-detail" */ "@/views/CategoryDetail.vue"
  );

const MyVideos = () =>
  import(/* webpackChunkName: "my-videos" */ "@/views/MyVideos.vue");

const Profile = () =>
  import(/* webpackChunkName: "profile" */ "@/views/Profile.vue");

const Newsletter = () =>
  import(/* webpackChunkName: "newsletter" */ "@/views/Newsletter.vue");

const BecomeContentProvider = () =>
  import(
    /* webpackChunkName: "become-content-provider" */ "@/views/BecomeContentProvider.vue"
  );

const AboutUs = () =>
  import(/* webpackChunkName: "about-us" */ "@/views/AboutUs.vue");

const ContactUs = () =>
  import(/* webpackChunkName: "about-us" */ "@/views/ContactUs.vue");

const LiveSeminars = () =>
  import(/* webpackChunkName: "live-seminars" */ "@/views/LiveSeminars.vue");

const Terms = () => import(/* webpackChunkName: "terms" */ "@/views/Terms.vue");

const PrivacyPolicy = () =>
  import(/* webpackChunkName: "privacy-policy" */ "@/views/PrivacyPolicy.vue");

const Events = () => import("@/views/Events.vue");

const Event = () => import("@/views/EventView.vue");

const QuickstartPayment = () => import("@/views/QuickPaymentView");

const withMainComponent = (component) => {
  return {
    header: AppHeader,
    default: component,
    footer: AppFooter,
  };
};

export default [
  {
    path: "/",
    name: "landing",
    components: withMainComponent(Landing),

    meta: {
      nonSubscribersOnly: true,
      title: "Bujin.tv - Fighting Arts Online",
    },
  },

  {
    path: "/shop",
    name: "shop",
    beforeEnter() {
      location.href = "https://shop.bujin.tv";
    },
  },
  {
    path: "/blog",
    name: "blog",
    beforeEnter() {
      location.href = "https://shop.bujin.tv/blogs/news";
    },
  },

  {
    path: "/events",
    name: "events",
    components: withMainComponent(Events),
    meta: {
      title: "Bujin.tv - Events",
    },
  },
  {
    path: "/event/:slug",
    name: "event",
    props: true,
    components: withMainComponent(Event),
    meta: {
      title: "Bujin.tv - Event Detail",
    },
  },

  {
    path: "/download",
    name: "download",
    components: withMainComponent(Download),

    meta: {
      title: "Bujin.tv - Download our App",
    },
  },

  {
    path: "/giftcard",
    name: "gift",
    components: withMainComponent(Gift),

    meta: {
      title: "Bujin.tv - Gift Card",
    },
  },

  {
    path: "/login",
    name: "login",
    components: withMainComponent(Login),

    meta: {
      guestsOnly: true,
      title: "Login",
    },
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    components: withMainComponent(ForgotPassword),
  },

  {
    path: "/subscribe",
    name: "subscribe",
    components: withMainComponent(Subscribe),

    meta: {
      nonSubscribersOnly: true,
      title: "Subscribe",
    },
  },
  {
    path: "/aau",
    name: "subscribePartnerAAU",
    components: withMainComponent(SubscribePartner),
    // props: { partnerId: 3 },
    props: {
      default: () => ({ partnerSlug: "aau" }),
    },
    meta: {
      nonSubscribersOnly: true,
      title: "Subscribe Bujin.tv <> AAU",
    },
  },
  {
    path: "/ku",
    name: "subscribePartnerKU",
    components: withMainComponent(SubscribePartner),
    // props: { partnerSlug: 3 },
    props: {
      default: () => ({ partnerSlug: "ku" }),
    },
    meta: {
      nonSubscribersOnly: true,
      title: "Subscribe Bujin.tv <> KU",
    },
  },
  {
    path: "/madera",
    name: "subscribePartnerMadera",
    components: withMainComponent(SubscribePartner),
    // props: { partnerSlug: 3 },
    props: {
      default: () => ({ partnerSlug: "madera" }),
    },
    meta: {
      nonSubscribersOnly: true,
      title: "Subscribe Bujin.tv <> Andres Madera",
    },
  },
  {
    path: "/p/:slug",
    name: "subscribePartnerAAU",
    components: withMainComponent(SubscribePartner),
    // props: { partnerId: 3 },
    props: {
      default: (route) => ({ partnerSlug: route.params.slug }),
    },
    meta: {
      nonSubscribersOnly: true,
      title: "Subscribe Bujin.tv <> AAU",
    },
  },
  {
    path: "/account",
    name: "account",
    components: withMainComponent(Account),
    meta: {
      requiresAuth: false,
      title: "Account",
    },
  },
  {
    path: "/main",
    name: "main",
    meta: {
      title: "Bujin.tv - Main",
    },
    components: withMainComponent(Main),
  },
  {
    path: "/media/:slug",
    name: "media-detail",
    components: withMainComponent(ProgramDetail),
    props: {
      default: (route) => ({
        slug: route.params.slug,
      }),
    },
  },
  {
    //This path is used only for backward compatibility
    path: "/program/:id",
    name: "program-detail",
    components: withMainComponent(ProgramDetail),
    props: {
      default: (route) => ({ programId: Number(route.params.id) }),
    },
  },
  {
    path: "/category/:id",
    name: "category-detail",

    components: withMainComponent(CategoryDetail),
    meta: {
      title: "Category Detail",
    },
    props: {
      default: (route) => ({ categoryId: Number(route.params.id) }),
    },
  },

  {
    path: "/search/:q",
    name: "search",
    meta: {
      title: "Search",
    },
    components: withMainComponent(Search),

    props: {
      default: (route) => ({ query: route.params.q }),
    },
  },

  {
    path: "/profile/:id",
    name: "profile",
    meta: {
      title: "Profile",
    },
    components: withMainComponent(Profile),
  },

  {
    path: "/my-videos",
    name: "my-videos",
    components: withMainComponent(MyVideos),

    meta: {
      requiresAuth: true,
      title: "My Videos",
    },
  },

  {
    path: "/programs",
    name: "programs",
    components: withMainComponent(Programs),
  },

  {
    path: "/newsletter",
    name: "newsletter",
    components: {
      default: Newsletter,
      footer: AppFooter,
    },
  },

  {
    path: "/become-content-provider",
    name: "become-content-provider",
    meta: {
      title: "Become Content Provider",
    },
    components: {
      default: BecomeContentProvider,
      footer: AppFooter,
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    meta: {
      title: "About Us",
    },
    components: withMainComponent(AboutUs),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    meta: {
      title: "Contact Us",
    },
    components: withMainComponent(ContactUs),
  },
  {
    path: "/live-seminars",
    name: "live-seminars",
    meta: {
      title: "Live Seminars",
    },
    components: withMainComponent(LiveSeminars),
  },
  {
    path: "/terms",
    name: "terms",
    meta: {
      title: "Terms & Conditions",
    },
    components: withMainComponent(Terms),
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      title: "Privacy Policy",
    },
    components: withMainComponent(PrivacyPolicy),
  },
  {
    path: "/quickstart-payment",
    name: "paymentGuide",
    meta: {
      title: "Quickstart Guide - Update Payment Information",
    },
    components: withMainComponent(QuickstartPayment),
  },
];
