import axios from "axios";
import merge from "lodash/merge";
import findLastIndex from "lodash/findLastIndex";

export default {
  initStore(state) {
    if (localStorage.getItem("store")) {
      this.replaceState(
        Object.assign(state, JSON.parse(localStorage.getItem("store")))
      );

      if (state.auth.token) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${state.auth.token}`;
      }
    }
  },

  setAuthToken(state, token) {
    state.auth.token = token;

    axios.defaults.headers.common["Authorization"] = token
      ? `Bearer ${token}`
      : null;
  },

  setAuthProfile(state, profileData) {
    state.auth.profile = profileData;
  },

  addToMyVideos(state, payload) {
    let found = state.my_videos.find((item) => item.id == payload.id);
    if (!found) {
      state.my_videos.push(payload);
    }
  },

  setSeminar(state, payload) {
    let lastProgramIndex = findLastIndex(
      state.programs,
      (item) => item.id == payload.id
    );
    if (lastProgramIndex > 0) {
      state.programs[lastProgramIndex] = payload;
    } else {
      state.programs.push(payload);
    }
  },

  setSensei(state, payload) {
    let lastSenseiIndex = findLastIndex(
      state.sensei,
      (item) => item.id == payload.id
    );
    if (lastSenseiIndex > 0) {
      state.sensei[lastSenseiIndex] = payload;
    } else {
      state.sensei.push(payload);
    }
  },

  setPrograms(state, newPrograms) {
    let existentPrograms = state.programs;

    newPrograms.forEach((newProgram) => {
      if (!newProgram.id) return;

      if (!existentPrograms[newProgram.id]) {
        existentPrograms[newProgram.id] = newProgram;
        return;
      }

      existentPrograms[newProgram.id] = merge(
        existentPrograms[newProgram.id],
        newProgram
      );
    });
  },

  setCurrentProgram(state, program) {
    state.currentProgram = program;
  },

  setCategory(state, category) {
    let lastCategoryIndex = findLastIndex(
      state.category,
      (item) => item.id == category.id
    );
    if (lastCategoryIndex > 0) {
      state.category[lastCategoryIndex] = category;
    } else {
      state.category.push(category);
    }
  },

  setAppTextItems(state, appTextItems) {
    state.appTextItems = appTextItems;
  },

  setPresenters(state, presenters) {
    state.presenters = presenters;
  },

  setCategories(state, categories) {
    state.categories = categories;
  },

  setSubscriptionPlans(state, subscriptionPlans) {
    state.subscriptionPlans = subscriptionPlans;
  },

  setPartnerSubscriptionPlans(state, subscriptionPlans) {
    state.partnerSubscriptionPlans = subscriptionPlans;
  },

  loadLang(state, payload) {
    state.language = payload;
  },

  // newToken(state, payload) {
  //   state.token = payload;
  // },

  // newProfile(state, payload) {
  //   state.profile = payload;
  // },

  // CHANGE_STATE_MENU(state, payload) {
  //   state.navigation.menu = payload;
  // },

  // CHANGE_STATE_TRILHOS(state, payload) {
  //   state.navigation.trilhos_hidden = payload;
  // },

  // CHANGE_STATE_OVERLAY(state, payload) {
  //   state.navigation.box_overlay = payload;
  // },
};
