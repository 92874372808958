<template>
  <div class="d-flex justify-content-center flex-wrap">
    <div
      v-for="(plan, index) in filteredSubscriptionPlans"
      :key="index"
      class="mb-3 mr-sm-2"
      :class="{ 'mr-sm-2': !isLastPlan(plan) }"
    >
      <base-button
        tag="router-link"
        :to="{ name: 'subscribe', query: { plan: plan.id } }"
        class="text-md text-nowrap font-weight-800 px-2 px-sm-5 py-3 mb-1 subscribe-button"
        :class="buttonClass"
        type="primary"
        :data-id="plan.id"
      >
        {{ priceText(plan) }}
      </base-button>

      <span
        v-if="trialText"
        class="d-block  text-center font-weight-600"
        :class="trialTextClass"
        >{{ trialText(plan) }}</span
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "landing-plan-buttons",

  props: {
    buttonClass: {
      type: String,
      required: false,
    },

    trialTextClass: {
      type: String,
      required: false,
    },
  },

  created() {
    this.$store.dispatch("fetchSubscriptionPlans");
  },

  computed: {
    ...mapState(["subscriptionPlans"]),

    filteredSubscriptionPlans() {
      return this.subscriptionPlans.filter(p => p.show_on_landing);
    },
  },

  methods: {
    priceText(plan) {
      let interval = "";

      // we should later retrieve this text from app text
      if (plan.interval === "monthly") interval = "per month";
      if (plan.interval === "yearly") interval = "per year";

      return `${plan.price.formatted} ${interval}`;
    },

    trialText(plan) {
      if (!plan.trial_days) return;

      return `${plan.trial_days} days for free`;
    },

    isLastPlan(index) {
      return index + 1 != this.filteredSubscriptionPlans.length;
    },
  },
};
</script>
