<template>
  <img
    :src="src"
    class="logo img-fluid"
    :class="{ 'with-shadow': shadow }"
    alt="Bujin.tv Logo"
  />
</template>

<script>
export default {
  name: "logo",

  props: {
    variation: {
      type: String,
      validator: (value) => {
        return ["white", "white_full", "black", "black_full"].includes(value);
      },
      default: "white_full",
    },

    shadow: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    src() {
      let assetMap = {
        black: "logo_black.png",
        black_full: "logo_black_full.svg",
        white: "logo_white.svg",
        white_full: "logo_white_full.svg",
      };
      return require(`@/assets/img/${assetMap[this.variation]}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  max-width: 500px;

  &.with-shadow {
    filter: drop-shadow(1px 1px 10px #000);
  }
}
</style>
