<template>
  <div>
    <header class="header-global">
      <base-nav class="navbar-main" transparent type="black-gradient" expand>
        <router-link
          slot="brand"
          class="navbar-brand mr-lg-5"
          :to="{ name: 'landing' }"
        >
          <logo
            width="70"
            height="40"
            variation="white"
            :style="{ height: '40px' }"
          ></logo>
        </router-link>

        <div class="row" slot="content-header" slot-scope="{ closeMenu }">
          <div class="col-6 collapse-brand">
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
              <logo variation="white_full" :style="{ height: '40px' }"></logo>
            </router-link>
          </div>
          <div class="col-6 collapse-close">
            <close-button @click="closeMenu"></close-button>
          </div>
        </div>

        <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
          <li class="nav-item" v-if="isAuthenticated">
            <router-link slot="title" class="nav-link" :to="{ name: 'main' }">
              <i class="ni d-lg-none"></i>
              <span class="nav-link-inner--text text-md text-white"
                >Bujin TV</span
              >
            </router-link>
          </li>

          <!--        <li class="nav-item" v-if="isAuthenticated">-->
          <!--          <router-link-->
          <!--            slot="title"-->
          <!--            class="nav-link"-->
          <!--            :to="{ name: 'my-videos' }"-->
          <!--          >-->
          <!--            <i class="ni d-lg-none"></i>-->
          <!--            <span class="nav-link-inner&#45;&#45;text text-md text-white">My List</span>-->
          <!--          </router-link>-->
          <!--        </li>-->

          <li class="nav-item" v-if="!isAuthenticated">
            <router-link slot="title" class="nav-link" :to="{ name: 'main' }">
              <i class="ni d-lg-none"></i>
              <span class="nav-link-inner--text text-md text-white"
                >Videos</span
              >
            </router-link>
          </li>

          <li class="nav-item">
            <router-link slot="title" class="nav-link" :to="{ name: 'shop' }">
              <i class="ni d-lg-none"></i>
              <span class="nav-link-inner--text text-md text-white">Shop</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link slot="title" class="nav-link" :to="{ name: 'events' }">
              <i class="ni d-lg-none"></i>
              <span class="nav-link-inner--text text-md text-white"
                >Events</span
              >
            </router-link>
          </li>
          <li class="nav-item">
            <router-link slot="title" class="nav-link" :to="{ name: 'blog' }">
              <i class="ni d-lg-none"></i>
              <span class="nav-link-inner--text text-md text-white">Blog</span>
            </router-link>
          </li>

          <!--          <li class="nav-item" v-if="!isAuthenticated">-->
          <!--            <router-link-->
          <!--              slot="title"-->
          <!--              class="nav-link"-->
          <!--              :to="{ name: 'live-seminars' }"-->
          <!--            >-->
          <!--              <i class="ni d-lg-none"></i>-->
          <!--              <span class="nav-link-inner&#45;&#45;text text-md text-white"-->
          <!--                >Live Seminars</span-->
          <!--              >-->
          <!--            </router-link>-->
          <!--          </li>-->

          <li class="nav-item" v-if="!isAuthenticated">
            <router-link
              slot="title"
              class="nav-link"
              :to="{ name: 'about-us' }"
            >
              <i class="ni d-lg-none"></i>
              <span class="nav-link-inner--text text-md text-white">About</span>
            </router-link>
          </li>

          <li class="nav-item">
            <i class="fa fa-search text-white-50"></i>
            <input
              id="searchquery"
              v-model="query"
              class="btn btn-link text-white text-left pl-2"
              placeholder="Search..."
              type="text"
            />
          </li>
        </ul>

        <ul class="navbar-nav align-items-lg-center ml-lg-auto">
          <li class="nav-item" v-if="!isAuthenticated">
            <router-link
              :to="{ name: 'login' }"
              class="nav-item btn btn-outline-primary btn-sm login-button"
              >Login
            </router-link>
            <router-link
              :to="{ name: 'subscribe', query: { plan: 1 } }"
              class="nav-item btn btn-primary btn-sm subscribe-button"
              >Sign up
            </router-link>
          </li>

          <base-dropdown v-else class="nav-item preference_menu">
            <a
              role="button"
              slot="title"
              href="#"
              class="nav-link"
              data-toggle="dropdown"
            >
              <i class="ni ni-single-02"></i>
              <span v-if="user" class="nav-link-inner--text">{{
                user.name
              }}</span>
            </a>
            <router-link :to="{ name: 'account' }" class="dropdown-item">
              <i class="ni ni-lock-circle-open text-muted"></i>
              Account Settings
            </router-link>
            <!--            <a-->
            <!--              v-if="user.permissions.is_admin"-->
            <!--              href="https://admin.bujin.tv/"-->
            <!--              class="dropdown-item"-->
            <!--              ><i class="fa fa-bolt text-primary"></i> Channel Admin</a-->
            <!--            >-->
            <a @click="logout" class="dropdown-item">
              <i class="ni ni-button-power text-danger"></i> Logout
            </a>
          </base-dropdown>
        </ul>
      </base-nav>
    </header>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BaseNav from "@/components/BaseNav";
import Logo from "@/components/Logo";
import CloseButton from "@/components/CloseButton";
// import SearchResults from "@/components/SearchResults";
// import algoliasearch from 'algoliasearch/lite';

export default {
  name: "app-header",

  components: {
    BaseNav,
    Logo,
    CloseButton,
  },

  data() {
    return {
      timer: null,
      query: "",
    };
  },

  updated() {
    this.search();
  },

  created() {},

  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
  },

  methods: {
    logout: function () {
      this.klaviyoTrack("Logout");
      this.$store.dispatch("logout");
      this.$router.push({ name: "landing" });
      window.location.reload();
    },

    search: function () {
      clearTimeout(this.timer);
      if (this.query != "") {
        this.timer = setTimeout(() => {
          this.$router.push({ path: "/search/" + this.query });
          this.query = "";
        }, 1200);

        // }else{
        //   this.hits = [];
      }
    },
  },

  watch: {
    $route: {
      handler: (to) => {
        document.title = to.meta.title;
      },
      immediate: true,
    },
  },
};
</script>
<style>
input#searchquery {
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}
</style>
