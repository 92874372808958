<template>
  <div class="container mb-sm">
    <collapse>
      <collapse-item
        v-for="(faqItem, index) in faqs"
        :key="index"
        class="border border-light rounded mb-sm"
        :name="index"
        :id="'faq-' + index"
      >
        <span
          slot="title"
          class="text-md text-dark font-weight-600"
          v-if="faqItem.question"
        >
          {{ faqItem.question }}
        </span>
        <span slot="tip" class="small">
          {{ faqItem.tip }}
        </span>

        <div class="row">
          <div
            v-for="(image, index) in faqItem.images"
            :key="index"
            class="col-auto mx-auto"
          >
            <img
              :src="image.url"
              v-if="image.url"
              :alt="imageName(faqItem.question)"
              class="mw-100"
            />
          </div>
        </div>

        <p
          :id="`faq-item-${index}`"
          class="text-sm text-dark"
          v-html="faqItem.answer"
          v-if="faqItem.answer"
        ></p>
      </collapse-item>
    </collapse>
  </div>
</template>

<script>
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";

export default {
  name: "BaseCollapsable",
  components: {
    Collapse,
    CollapseItem,
  },
  props: {
    faqItems: {
      required: true,
      type: Array,
    },
  },
  methods: {
    isVisible: function (faqItem) {
      if (faqItem !== "undefined") {
        return true;
      }
    },
    imageName: function (title) {
      return title + " image";
    },
  },
  computed: {
    faqs() {
      return this.faqItems.filter((i) => i.question !== "");
    },
  },
};
</script>

<style scoped></style>
