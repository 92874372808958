/* eslint-disable no-undef */
import Vue from "vue";
const Klaviyo = {
  install(Vue) {
    Vue.prototype.klaviyoIdentify = (email, name) => {
      if (name == "") {
        _learnq.push([
          "identify",
          {
            $email: email,
          },
        ]);
      } else {
        _learnq.push([
          "identify",
          {
            $email: email,
            $first_name: name,
          },
        ]);
      }
    };
    Vue.prototype.klaviyoCreateAccount = () => {
      _learnq.push(["track", "Account Created"]);
    };
    Vue.prototype.klaviyoCancelAccount = () => {
      _learnq.push(["track", "Account Canceled"]);
    };
    Vue.prototype.klaviyoLogin = () => {
      _learnq.push(["track", "Login"]);
    };
    Vue.prototype.klaviyoPaymentSuccess = () => {
      _learnq.push(["track", "Payment Added"]);
    };
    Vue.prototype.klaviyoPaymentError = () => {
      _learnq.push(["track", "Payment Error"]);
    };
    Vue.prototype.klaviyoTrack = eventName => {
      _learnq.push(["track", eventName]);
    };
    Vue.prototype.klaviyoTrackPlayMovie = program => {
      _learnq.push(["track", "Viewed Program", program]);
    };
  },
};
Vue.use(Klaviyo);
